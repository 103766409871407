.cost {
  position: absolute;
  width: 300px;
  height: 432px;
  right: -5%;
  top: 19.4%;
  padding-top: 32px;
  border-radius: 32px;
  background: linear-gradient(90.3deg, #fffcfc51 6.36%, #ffffff84 101.69%);
  z-index: 5;
  transition: 0.4s;
  backdrop-filter: blur(10px);
  opacity: 0;
  box-shadow: 0px 0px 25.8125px rgba(0, 0, 0, 0.07);
  animation: boxOpacity 0.5s ease-in-out 0.3s forwards;

  &::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: 35px;
    padding: 1px;
    background: linear-gradient(90deg, #fffcfc87, #ffffff);
    z-index: -2;
    -webkit-mask: linear-gradient(#ffffff 0 0) content-box,
      linear-gradient(#ffffff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  &_title {
    font-family: Montserrat700;
    font-weight: 700;
    color: #ffffff;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 17px;
    text-align: center;
  }

  &_button {
    overflow: hidden;
    height: 255px;
    transition: 1s;

    & .box-wrapper > button {
      width: 262px;
      height: 44px;
      border: 0px transparent;
      border-radius: 34px;
      margin: 4px 0;
      background: linear-gradient(91.91deg, #0524c9cc 0%, #228affcc 119.2%);
      font-family: Montserrat800;
      color: #ffffff;
      font-size: 20px;
      cursor: pointer;
    }

    &__act {
      height: 336px;
    }
  }
}

.cost__act {
  height: 512px;
}

.cost-text {
  width: 200px;
  height: 18px;
  font-family: Montserrat500;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin: 0 auto;
  margin-bottom: 4px;
  color: #fff;
}

#imputCost {
  font-size: 16px;
  line-height: 18px;
  width: 262px;
  height: 42px;
  border: 1px solid #0057b8;
  border-radius: 34px;
  background-color: transparent;
  outline: none;
  color: #fff;
  padding: 0;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
  margin-bottom: 4px;

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &[type='number']:hover,
  &[type='number']:focus {
    -moz-appearance: number-input;
  }
}

#imputCost::placeholder {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #fff;
  transform: translateX(35%);
}

.error {
  &::placeholder {
    transform: translateX(20%) !important;
    color: rgb(253, 0, 0) !important;
  }
}

// .arrow {
//     transform: rotate(0deg);
//     transition: 0.5s;
// }

// .arrow__act {
//     transform: rotate(-90deg);
// }

.btn-donate {
  width: 262px;
  height: 44px;
  border: 0px transparent;
  border-radius: 34px;
  margin: 4px 0;
  background: linear-gradient(91.91deg, #0524c9cc 0%, #228affcc 119.2%);
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
}

@media (min-width: 425px) {
  .cost {
    right: 0;
  }
}

@media (max-width: 770px) {
  .cost_button__act {
    padding: 0 20px;
  }
}

@media (min-width: 700px) {
  .cost {
    top: 30%;
    width: 412px;
    padding-top: 44px;
    height: 454px;
  }

  .cost_title {
    margin: 0 0 48px 0;
  }

  .cost-text {
    width: 235px;
    font-size: 14px;
    margin-top: 29px;
    margin-bottom: 14px;
  }

  .box-wrapper {
    display: flex;
    gap: 15px;
    padding: 0 39px;
  }

  .btn-donate {
    width: 295px;
    display: block;
    margin: 0 auto;
    margin-top: 12px;
  }

  .input-donate {
    display: block;
    margin-top: 12px;
  }

  #imputCost {
    padding-left: 30px;
    padding-right: 30px;
    width: 295px;
  }

  .type-of-cards {
    margin-top: 51px;
    width: 333.87px;
    gap: 21px;
  }
}

.cost-international {
  position: absolute;
  width: 300px;
  height: 360px;
  right: -5%;
  top: 19.4%;
  padding-top: 32px;
  border-radius: 32px;
  background: linear-gradient(90.3deg, #fffcfc51 6.36%, #ffffff84 101.69%);

  transition: 1s;
  backdrop-filter: blur(10px);
  box-shadow: 0px 0px 25.8125px rgba(0, 0, 0, 0.07);
  opacity: 0;
  animation: boxOpacity 0.5s ease-in-out 0.3s forwards;

  &::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: 38px;
    padding: 1px;
    background: linear-gradient(90deg, #fffcfc87, #ffffff);
    z-index: -2;
    -webkit-mask: linear-gradient(#ffffff 0 0) content-box,
      linear-gradient(#ffffff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  &_wrapper {
    margin: 0 0 33px 0;
    text-align: center;
  }
  &_title {
    font-family: Montserrat700;
    font-weight: 700;
    color: #ffffff;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 17px;
    text-align: center;
  }

  &_button {
    overflow: hidden;
    height: 236px;
    transition: 1s;

    & .box-wrapper > button {
      width: 262px;
      height: 44px;
      border: 0px transparent;
      border-radius: 34px;
      margin: 4px 0;
      background: linear-gradient(91.91deg, #0524c9cc 0%, #228affcc 119.2%);
      font-family: Montserrat800;
      color: #ffffff;
      font-size: 20px;
      cursor: pointer;
    }

    &__act {
      height: 336px;
    }
  }
}

.cost__act {
  height: 512px;
}

.type-of-cards-inter {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 33px;
  gap: 21px;
}

@media (min-width: 425px) {
  .cost-international {
    right: 0;
  }
}

@media (max-width: 770px) {
  .cost_button__act {
    padding: 0 20px;
  }
}

@media (min-width: 700px) {
  .cost-international {
    top: 30%;
    width: 412px;
    padding-top: 44px;
    height: 320px;
  }

  .type-of-cards-inter {
    margin: 0 auto;
    margin-top: 33px;
    width: 333.87px;
    gap: 21px;
  }
}

.box-wrapper_form {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-template-rows: auto;
  gap: 15px;
  padding: 0 39px;
}
.cost-btn_form {
  min-width: 105px;
  height: 44px;
  border: 0px transparent;
  border-radius: 34px;
  margin: 4px 0;
  background: linear-gradient(91.91deg, #0524c9cc 0%, #228affcc 119.2%);
  font-family: Montserrat800;
  color: #ffffff;
  font-size: 18px;
  cursor: pointer;
  white-space: nowrap;
}

@keyframes boxOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
