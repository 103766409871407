.slide {
  width: 80%;
}

.carusel-cast-style {
  display: flex;
  justify-content: center;

  &_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 380px;
    margin: 0 24px 0 0;
  }

  &_img {
    margin: 0 0 0 24px;
  }
}

.carousel-control-prev {
  left: -30px !important;
}

.carousel-control-next {
  right: -30px !important;
}

.carousel-control-next,
.carousel-control-prev {
  width: auto !important;
  opacity: 1 !important;
}

.carousel {
  height: 635px;
}

@media (max-width: 1080px) {
  .carousel {
    height: auto;
  }
  .carusel-cast-style {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 950px;

    &_item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 380px;
      margin: 58px 0 0 0;
    }

    &_img {
      margin: 0 0 24px 0px;
    }
  }

  .h2-castem {
    text-align: center;
  }
}

@media (max-width: 720px) {
  .carusel-cast-style_img > img {
    width: 440px;
  }
}

@media (max-width: 600px) {
  .carusel-cast-style {
    min-height: 680px;
    &_item {
    }

    &_img > img {
      width: 287px;
    }
  }
}

@media (max-width: 420px) {
  .carusel-cast-style_img > img {
    width: 237px;
  }
}
