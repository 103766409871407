.how-its-worck {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 1180px;
  margin: 266px auto 0;
  flex-wrap: wrap;

  &_title {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
    // padding: 0 2% 0 2%;
    // width: 50%;
  }

  @media (min-width: 767px) {
    &_info {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .charity-hero-container {
      display: flex;
      flex-direction: row;
    }

    //remove small images in high resolution
    .pick {
      display: none;
    }
  }
}

.web-pay {
  display: flex;
  flex-direction: row;
  position: relative;
  border-radius: 31.971px;
  margin: 10% auto;
  padding: 1% 1%;
  max-width: 1053px;
  max-height: 80px;
  box-sizing: border-box;
  background: linear-gradient(
    to bottom right,
    rgba(34, 138, 255, 0.48),
    rgba(34, 138, 255, 0.48)
  );
}

.hwch-info {
  display: flex;
  margin-bottom: 32px;

  &_info {
    display: flex;
    flex-direction: column;
    max-width: 760px;
  }
}

.mot_1 {
  width: 90%;
  padding: 0 2% 0 2%;
}

.mot_2 {
  width: 100%;
  padding: 0 2% 0 2%;
}

.mot_3 {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0 2% 0 2%;
}

//сужение
@media (max-width: 768px) {
  .img_1234 {
    display: none;
  }

  .slick34_img {
    max-width: 100%;
    margin: 0 0 0 30%;
  }

  .web-pay {
    display: flex;
    flex-direction: row;
    position: relative;
    border-radius: 31.971px;
    margin: 10% auto;
    padding: 1% 1%;
    max-width: 1053px;
    max-height: 80px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.592);
  }

  .web-pay-txt {
    display: none;
  }

  .charity-hero-container {
    display: flex;
    flex-direction: column;
  }
}

.img_1234 {
  max-width: 100%;
}

.pick {
  margin-bottom: 4%;
}

// .234
// img {
//   max-width: 100%;
// }

.b_g_c {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  border-radius: 31.971px;
  margin: 0 auto;
  padding: 1% 1%;
  max-width: 1105px;
  box-sizing: border-box;
  box-shadow: 0px 0px 24.9774px rgba(0, 0, 0, 0.07);
}

.web-pay-txt {
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: 800;
  font-size: 32px;
  line-height: 39px;
  /* identical to box height */
  text-align: left;
  color: #000000;
  opacity: 0.9;
}

.h2_B {
  margin-top: 10%;
  margin-left: 22%;
  font-style: normal;
  font-weight: 800;
  font-size: 46px;
  line-height: 50px;
}

.slick_img {
  //max-width: 100%;
  margin: 5% 0 5% 0;
}

.slick34_img {
  max-width: 100%;
}

.cap_h2 {
  font-family: Montserrat700;
  font-style: normal;
  font-size: 30px;
  line-height: 39px;
  text-transform: uppercase;
  margin-top: -30%;
}

.main_txt {
  font-family: Montserrat400;
  font-style: normal;
  font-size: 22.9792px;
  line-height: 28px;
  color: #000000;
}

.text_frame {
  position: relative;
  top: 30%;
  margin: 0 0 10% 0;
  padding: 0 0 5% 25%;
}

@media (max-width: 1024px) {
  .cap_h2 {
    font-family: Montserrat700;
    font-style: normal;
    font-size: 32px;
    line-height: 39px;
    text-transform: uppercase;
    margin-left: -15%;
    margin-top: -40%;
  }

  .main_txt {
    font-family: Montserrat400;
    font-style: normal;
    font-size: 22px;
    line-height: 28px;
    color: #000000;
  }
}

@media (max-width: 1024px) {
  .cap_h2 {
    font-family: Montserrat700;
    font-style: normal;
    font-size: 32px;
    line-height: 39px;
    text-transform: uppercase;
    margin-left: -15%;
    margin-top: -40%;
  }

  .main_txt {
    font-family: Montserrat400;
    font-style: normal;
    font-size: 22px;
    line-height: 28px;
    color: #000000;
  }
}

@media (max-width: 979px) {
  .cap_h2 {
    font-family: Montserrat700;
    font-style: normal;
    font-size: 32px;
    line-height: 39px;
    text-transform: uppercase;
    margin-left: -5%;
    margin-top: -4%;
  }

  .main_txt {
    font-family: Montserrat400;
    font-style: normal;
    font-size: 22px;
    line-height: 28px;
    color: #000000;
  }
}

@media (max-width: 920px) {
  .cap_h2 {
    font-family: Montserrat700;
    font-style: normal;
    font-size: 32px;
    line-height: 39px;
    text-transform: uppercase;
    margin-left: -25%;
    margin-top: -4%;
  }

  .main_txt {
    font-family: Montserrat400;
    font-style: normal;
    font-size: 22px;
    line-height: 28px;
    color: #000000;
  }
}

@media (max-width: 820px) {
  .cap_h2 {
    font-family: Montserrat700;
    font-style: normal;
    font-size: 32px;
    line-height: 39px;
    text-transform: uppercase;
    margin-left: -25%;
  }

  .main_txt {
    font-family: Montserrat400;
    font-style: normal;
    font-size: 22px;
    line-height: 28px;
    color: #000000;
  }
  .text_frame {
    width: 100%;
    height: 410px;
  }
}

@media (max-width: 420px) {
  .cap_h2 {
    font-family: Montserrat700;
    font-style: normal;
    font-size: 20px;
    line-height: 39px;
    text-transform: uppercase;
  }

  .main_txt {
    font-family: Montserrat400;
    font-style: normal;
    font-size: 16px;
    line-height: 28px;
    color: #000000;
    padding: 0 5%;
  }
  .text_frame {
    width: 100%;
    height: 300px;
  }
  //.caroBox_img {
  //  position: relative;
  //  max-width: 50%;
  //}
}

@media (max-width: 280px) {
  .cap_h2 {
    font-family: Montserrat700;
    font-style: normal;
    font-size: 20px;
    line-height: 39px;
    text-transform: uppercase;
    margin-top: -35%;
    margin-left: -10%;
    padding: 1% 7% 0 3%;
  }

  .main_txt {
    font-family: Montserrat400;
    font-style: normal;
    font-size: 12px;
    line-height: 28px;
    color: #000000;
    padding: 0 5% 24% 0;
  }
  .text_frame {
    width: 100%;
    height: 410px;
  }
}
